import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'edit-settings',
    loadChildren: () => import('./edit-settings/edit-settings.module').then( m => m.EditSettingsPageModule)
  },
  {
    path: ':city/:pg',
    loadChildren: () => import('./content-detail/content-detail.module').then( m => m.ContentDetailPageModule)
  },
  {
    path: ':city',
    loadChildren: () => import('./city-index/city-index.module').then( m => m.CityIndexPageModule)
  },
  {
    path: ':pgType/:loc/:slug',
    loadChildren: () => import('./fourohfour/fourohfour.module').then( m => m.FourohfourPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
